(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jspdf"), require("html2canvas"));
	else if(typeof define === 'function' && define.amd)
		define("html2pdf", ["jspdf", "html2canvas"], factory);
	else if(typeof exports === 'object')
		exports["html2pdf"] = factory(require("jspdf"), require("html2canvas"));
	else
		root["html2pdf"] = factory(root["jspdf"], root["html2canvas"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_jspdf__, __WEBPACK_EXTERNAL_MODULE_html2canvas__) {
return 