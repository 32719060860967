import React from 'react'
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import { useLocation } from "react-router-dom";
import { BsThreeDots } from 'react-icons/bs'
import { MdFileCopy, MdOutlineDownloadForOffline } from 'react-icons/md'
import { SlReload } from "react-icons/sl";
import { useEffect } from 'react';
import { sendId, sendAIData, regenerateAIData, generateImageId } from "../../Actions/TasksAction"
import { useDispatch } from "react-redux";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { setAlert } from '../../Actions/AlertAction';
import queryString from 'query-string'
import { appName } from '../../Global/Global';
const ResponsePage = () => {
    const [data, setData] = useState({
        data: [],
        orginalContent: ""
    })
    const [inputData, setInputData] = useState([])
    const [inputstatus, setInputStatus] = useState(false)
    const [loader, setLoader] = useState({
        one: false,
        two: false,
        three: false
    });
    const [mainLoader, setMainLoader] = useState(true);
    const [categoryId, setCategoryId] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [clickBtn, setClickBtn] = useState(-1);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let id = queryString.parse(location.search).id;
    let tId = queryString.parse(location.search).tId;
    let id_Data = {
        id: id
    }
    const SendAPI_ID = () => {
        dispatch(sendId(id_Data, setMainLoader, setData, setInputData, setInputStatus, setCategoryId));
    }
    //console.log(inputData);
    const GenerateImage = () => {
        setLoader({
            ...loader,
            three: true
        })
        dispatch(generateImageId(data.id, data.orginalContent, setImageURL, setLoader, loader));
    }


    //console.log(imageURL, "check url")

    const handleChange = (e) => {
        let { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
    }
    const handleChangeInput = (e, index) => {
        let { name, value } = e.target
        let inputs = [...data.data]

        inputs[index] = {
            ...inputs[index],
            [name]: value
        }
        setData({
            ...data,
            data: inputs
        });
    }
    const saveHandle = (data) => {
        setClickBtn(1)
        let data2 = [];
        if (!data.length) {
            return
        }
        else {
            for (let index = 0; index < data.length; index++) {
                data2.push(Object.keys(data[index]));
            }
            let flag = false;
            for (let i = 0; i < data2.length; i++) {
                // console.log("Data",data[i][data2[i]]);
                if (data[i][data2[i]] === '') {
                    flag = false;
                    break
                } else {
                    flag = true;
                }
            }
            if (!flag) {
                dispatch(setAlert("Enter the required fields", "danger"))
            }
        }

    }
    const regenerateHandle = (data) => {
        setClickBtn(2)
        // console.log(data.length);

        let data2 = [];
        if (!data.length) {
            return
        }
        else {
            for (let index = 0; index < data.length; index++) {
                data2.push(Object.keys(data[index]));
            }
            let flag = false;
            for (let i = 0; i < data2.length; i++) {
                // console.log("Data",data[i][data2[i]]);
                if (data[i][data2[i]] === '') {
                    flag = false;
                    break
                } else {
                    flag = true;
                }
            }
            if (!flag) {
                dispatch(setAlert("Enter the required fields", "danger"))
            }
        }

    }
    const submitData = (e) => {
        e.preventDefault();
        if (clickBtn === 1) {
            // TempData()
            if (data.orginalContent) {
                setLoader({
                    ...data,
                    one: true
                })

                dispatch(sendAIData(data, setLoader, navigate))
            } else {
                dispatch(setAlert("Please fill the details", "danger"))
            }

        }
        else if (clickBtn === 2) {

            // if (inputstatus) {
            regenerateTask()
            // } else {
            //     dispatch(setAlert("Please fill the details", "danger"))
            // }

        }


    }
    const regenerateTask = () => {
        setLoader({
            ...data,
            two: true
        })
        dispatch(regenerateAIData(data, tId, setData, setLoader))
    }
    let Mdata;
    const MDATA = () => {
        Mdata = data.data.map((pdata) => {
            return pdata
        })
    }
    useEffect(() => {
        MDATA()
    }, []);
    //console.log(Mdata);
    //console.log("MData",Mdata);

    let data2 = data.orginalContent;
    // let Tdata = data.data;
    // console.log(Tdata);

    // Tdata?.map((data)=>{
    //    console.log(data.Object.keys(data));
    // })
    const DownloadImg = () => {
        const link = document.createElement('a');
        link.href = imageURL;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    }
    // console.log("Data AI", data);

    const CopyData = () => {
        const data = document.getElementById("floatingTextarea");
        data.select();
        data.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(data.value);
        // console.log(data)
    }

    const CreateDocFile = () => {
        let documentName = data.campaignName;
        let fileDownload = document.createElement("a");
        let copyText = document.getElementById(`floatingTextarea`)
        fileDownload.href = `data:application/vnd.ms-word;charset=utf-8,` + encodeURIComponent(copyText.value)

        fileDownload.download = `${documentName}.doc`;
        fileDownload.click();
    }
    useEffect(() => {
        SendAPI_ID()
    }, []);

    const formatText = (text) => {
        const regex = /(.{100})/g;
        return text.replace(regex, '$1<br>');
    };
    const printHandle = () => {
        let copyText = document.getElementById(`floatingTextarea`)
        const element = document.createElement('div');
        element.innerHTML = `<pre>${formatText(copyText.value)}</pre>`;
        element.style.fontFamily = 'Arial';
        element.style.fontSize = '14px';
        element.style.color = 'black';
        element.style.padding = '10px';
        element.style.whiteSpace = 'pre-wrap';
        const options = {
            margin: 10,
            filename: `${appName}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save();
    }
    return (

        mainLoader ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :
            <>
                <TitleBar title="Dashboard" />
                <Navbar />

                <section className="siteWrap">
                    <div className="Project_Wrap">
                        <div className="container-fulid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="Response_page_tittle manage_space_2" >
                                        <i className="fa-sharp fa-solid fa-arrow-left" style={{ color: "#fff" }}
                                            onClick={() => { navigate(-1) }}
                                        ></i>
                                        <h2 className='pb-1' >{data.campaignName}</h2>
                                    </div>
                                    <hr className='manage_space_1' style={{ opacity: "1", color: "#f721a0", }} />
                                    <p className='manage_space_1 pt-2' style={{ color: "#fff" }} >{data.task}</p>
                                </div>
                            </div>

                        </div>
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="Response_main_wrap" >
                                        {
                                            categoryId !== "2" ? (
                                                <form onSubmit={(e) => { submitData(e) }} id='responseForm'>
                                                    <div className=""></div>
                                                    <div className="row align-items-center">
                                                        {
                                                            (data.data.length > 0) ?

                                                                (
                                                                    data.data.map((task, index) => {

                                                                        return (
                                                                            <React.Fragment>

                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label for="exampleFormControlInput1" className="form-label ">{Object.keys(task)[0].replace("x", "Number ")} :</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="exampleFormControlInput1"
                                                                                            placeholder="name@example.com"
                                                                                            value={task[Object.keys(task)]}
                                                                                            name={Object.keys(task)}
                                                                                            required
                                                                                            onChange={(e) => handleChangeInput(e, index)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })

                                                                ) : ''
                                                        }

                                                    </div>
                                                    <div className="response_heading">
                                                        <h4> Result</h4>
                                                        <hr style={{ opacity: "1", color: "#f721a0", margin: " 10px 0" }} />

                                                        <div className="row mt-4">
                                                            <div className="col-md-12">
                                                                <div className="form-floating">
                                                                    <label for="floatingTextarea" style={{ right: "0", top: "-9px", height: "auto", borderTopLeftRadius: "8px ", borderTopRightRadius: "8px", background: "#000", borderTop: '1px solid #6a6b70', borderLeft: '1px solid #6a6b70', borderRight: '1px solid #6a6b70' }} > Task Response</label>

                                                                    <textarea
                                                                        className="form-control response_textarea"
                                                                        style={{ paddingTop: "60px", marginTop: "8px" }}
                                                                        placeholder="Leave a comment here"
                                                                        id="floatingTextarea"
                                                                        value={data.orginalContent}
                                                                        name="orginalContent"
                                                                        onChange={(e) => handleChange(e)}
                                                                    ></textarea>

                                                                    <div className="dropdown Task_response_drop">
                                                                        <button className="btn  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <BsThreeDots style={{ color: "#fff", fontSize: "28px" }} />
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            <span
                                                                                onClick={CopyData}
                                                                                className="dropdown-item d-flex  cursor-pointer"

                                                                                style={{ fontSize: "16px", color: "#000", fontFamily: "arial", fontWeight: "500", }}
                                                                            >
                                                                                <MdFileCopy className='mr-2' size={20} />
                                                                                COPY

                                                                            </span>

                                                                            <span
                                                                                onClick={CreateDocFile}
                                                                                className="dropdown-item cursor-pointer"
                                                                                style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                                                            >
                                                                                <MdOutlineDownloadForOffline className='mr-1' size={20} />
                                                                                DOC
                                                                            </span>

                                                                            <span

                                                                                onClick={printHandle}
                                                                                className="dropdown-item cursor-pointer"
                                                                                style={{ fontSize: "15px", color: "#000", fontFamily: "arial", fontWeight: "500" }}
                                                                            >
                                                                                <MdOutlineDownloadForOffline className='mr-1' size={20} />
                                                                                PDF
                                                                            </span>


                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "30px", textAlign: "end", with: "100%" }}>
                                                        <button
                                                            className="btn btn-primary me-2"
                                                            type='submit'
                                                            onClick={() => { saveHandle(data.data) }}>
                                                            {loader.one ? " Saving" : "Save "}
                                                            {loader.one ?
                                                                <i className="fa fa-spinner fa-spin mx-1" />
                                                                : ""}
                                                        </button>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => { regenerateHandle(data.data) }}
                                                            type="submit"
                                                        >{loader.two ? "Regenerating " : "Regenerate "}
                                                            {loader.two ?
                                                                <i className="fa fa-spinner fa-spin mx-1" />
                                                                : ""}
                                                        </button>

                                                    </div>

                                                </form>
                                            ) : (
                                                <form onSubmit={(e) => { submitData(e) }}>
                                                    <div className="chat-single ">
                                                        <div className="chat-txt" style={{ position: "relative" }}>
                                                            <div className="chat-txt-in">
                                                                <div className="chat-txt-inner" id="message2">
                                                                    <span disabled="" style={{ cursor: "pointer" }} onClick={GenerateImage} contenteditable="false">{data.orginalContent}</span>
                                                                    <div className="cursor-pointer graphic-text my-3">
                                                                        {/* <span disabled="" contenteditable="false">2. A teenage boy lounges on his bed in his cluttered bedroom, surrounded by a tangle of electronic devices and cables. He is holding a sleek new smartphone in his hand, with a bright, colorful screen that lights up his face. His other hand is scrolling through a social media feed on a tablet, while a laptop sits open on his desk nearby. The room is lit by a bright overhead light, casting sharp shadows across the chaotic scene. Posters and stickers cover the walls, and a skateboard leans against the dresser in the corner. The overall effect is one of youthful energy and tech-savvy cool, with the jumble of devices and the bright colors of the screens creating a sense of constant stimulation and activity. Medium: Digital Art. Style: Urban, Youthful. Lighting: Bright, Overhead. Colors: Bright, Bold. Composition: Overhead Shot, Wide Angle Lens, High Clutter.</span><br /><br /> */}
                                                                        <div className="text-center my-2">
                                                                            <div>{
                                                                                loader.three ? (
                                                                                    <i className="fa fa-spinner fa-spin mx-1" style={{ color: "#f721a0", fontSize: "30px" }} />
                                                                                ) : imageURL.length === 0 ? '' :
                                                                                    <>
                                                                                        <img src={imageURL} alt="Image" width="70%" />
                                                                                        <br />
                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                            <button onClick={() => { DownloadImg() }} className="demoLink me-3">Download</button>
                                                                                            <button onClick={GenerateImage} className="demoLink" title='Reload'><SlReload className='mr-1' size={20} /></button>
                                                                                        </div>
                                                                                    </>
                                                                            }

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div style={{ marginTop: "30px", textAlign: "end", with: "100%" }}>
                                                        <button
                                                            className="btn btn-primary me-2"
                                                            onClick={() => { setClickBtn(1) }}
                                                            type='submit'>
                                                            {loader.one ? " Saving" : "Save "}
                                                            {loader.one ?
                                                                <i className="fa fa-spinner fa-spin mx-1" />
                                                                : ""}
                                                        </button>
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={() => { setClickBtn(2) }}
                                                            type='submit'
                                                        >{loader.two ? "Regenerating " : "Regenerate "}
                                                            {loader.two ?
                                                                <i className="fa fa-spinner fa-spin mx-1" />
                                                                : ""}
                                                        </button>

                                                    </div>
                                                </form>
                                            )

                                        }


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section >

            </>
    )
}

export default ResponsePage
