import produce from "immer"
const initialState = {
    articles: [],
    videos: []
}

export const SupportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'ADD_ARTICLES':
            return produce(state, (draft) => {
                draft.articles = action.payload
            })

        case 'ADD_VIDEOS':
            return produce(state, (draft) => {
                draft.videos = action.payload
            })

        default:
            return state
    }
}