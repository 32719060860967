import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ProfileImage from "./ProfileImage";
import ProfileInfo from "./ProfileInfo";
import ProfilePassword from "./ProfilePassowrd";
import TitleBar from "../TitleBar";

const Profile = () => {
    return (
        <>
            <TitleBar title="Profile" />
            <Navbar />

            <section className="siteWrap">
                <div className="siteWrap-in">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <ProfileImage />
                                <ProfileInfo />
                                <ProfilePassword />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Profile;