import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { changeProfile } from "../../Actions/AuthAction";
import Swal from "sweetalert2";

const ProfileImage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
   

    const [profileStatus, setProfileStatus] = useState(false);

    const onImageChange = (e) => {

        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);

                setProfileStatus(true)
                dispatch(changeProfile(formData, setProfileStatus))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    return (
        <>
            <div className="proImage-wrap">
                <div className="proImage">
                    <div className="proImage-holder">
                        <img src={auth.user.image} alt="user-img" width="100px" height="100px" />
                        <div className={`${profileStatus === false ? 'hide-social' : ''} my-account-loader`}>
                            <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "30px",color:"#c238a4" }} />
                        </div>
                        <div className="custom-file">
                            <FiEdit />
                            <input
                                type="file"
                                onChange={(e) => onImageChange(e)}
                                className="custom-file-input"
                                accept="image/*"
                            />
                        </div>
                    </div>
                    <h5>{auth.user ? auth.user.name : null}</h5>
                    <button className="demoLink-2 mt-2">
                        {
                            auth.user ?
                                auth.user.membership.split("__")[(auth.user.membership.split("__").length - 1)]
                                : null
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default ProfileImage;