import React from "react";
import Navbar from "../Navbar";
import HelpContent from "./HelpContent";
import HelpVideos from "./HelpVideos";
import Footer from "../Footer";
import CustomerSupport from "./CustomerSupport";
import TitleBar from "../TitleBar";

const HelpSupport = () => {

    return (
        <>
            <TitleBar title="Help & Support" />
            <Navbar />

            <HelpContent />
            {/* <HelpVideos /> */}
            <CustomerSupport />

            <Footer />
        </>
    )
}

export default HelpSupport;