import React from "react";
import EditTeamClient from "./EditTeamClient";

const ManageTeam = ({ users, fetchClientTeam, type }) => {
    return (
        <>
            <div className="profileBlock-wrap profileBlock-wrap-last">
                <h2 className="infoTitle">Manage {type} Accounts</h2>
                <div className="profileBlock tableWrap mt-0">

                    <table className="table table-striped table-dark">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email Address</th>
                                <th scope="col">Created</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ?
                                users.map((user, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <EditTeamClient
                                                type={type}
                                                user={user}
                                                index={index}
                                                fetchClientTeam={fetchClientTeam}
                                                key={`team_${index}`}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : ''
                            }
                        </tbody>
                    </table>


                </div>

            </div>

        </>
    )
}

export default React.memo(ManageTeam);