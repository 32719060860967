const initialState = {
    message: "",
    type: "",
    activeNavbar: ""
}

export const AlertReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_ALERT':
            return {
                ...state,
                ...payload
            }
        case 'REMOVE_ALERT':
            return {
                ...state,
                message: "", type: ""
            }
        case 'SET_NAV':
            return {
                ...state,
                activeNavbar: payload
            }
        default:
            return state
    }
}



