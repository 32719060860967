import React, { useState } from 'react'
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import Footer from "../Footer";
// import dummyProduct from '../../images/dummyProduct.jpg'
import { BsPlusLg } from 'react-icons/bs';
import { GoLinkExternal } from "react-icons/go";
import dots from '../../images/dots.png';
import { BsThreeDots } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ImEmbed2 } from "react-icons/im";
import { Dropdown } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { GrShareOption } from "react-icons/gr";
import { VscCopy } from "react-icons/vsc";
import { AiOutlineSearch, AiTwotoneEdit } from "react-icons/ai"
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { allProjects, deleteProjectWithId } from "../../Actions/TasksAction"
import ProjectRows from './ProjectRows';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
const Projects = () => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [id, setId] = useState(-1);
    const [isClicked, setIsClicked] = useState(-1);
    const [isDelete, setIsDelete] = useState(0);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [searchPage, setSearchPage] = useState({
        start: 1,
    })
    const [totalDataLength, setTotalDataLength] = useState(0);
    const selector = useSelector(state => state.auth);
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const searchData = (page = 1, type) => {
        setLoader(true)
        let dataVal = {
            start: page,
            end: 5
        }
        dispatch(allProjects(dataVal, setSearchPage, page, data, setData, setTotalDataLength, type, setLoader));
    }
    useEffect(() => {
        searchData()
    }, []);
    return (
        <>
            <TitleBar title="Dashboard" />
            <Navbar />

            <section className="siteWrap">
                <div className="Project_Wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Project_tittle align-items-center">
                                    <h2>Your Tasks</h2>
                                    {selector.user.isClient === "0" ? (
                                        <Link to="/create">
                                            <button className="demoLink mt-0"><BsPlusLg size={20} style={{ marginRight: "5px", marginBottom: "3px" }} /> Create Tasks</button>
                                        </Link>)
                                        : ''
                                    }
                                </div>
                                <hr style={{ opacity: "1", color: "#f721a0", margin: " 10px 120px" }} />
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="Project_Table" style={{ marginTop: "40px" }}>
                                        <div className="Project_Sort">
                                            <div className="Project_Sort_single">
                                                <div className="proSearch">
                                                    <span className='searchIcon'><AiOutlineSearch /></span>
                                                    <input
                                                        className='inpSearch withIcon'
                                                        type="text"
                                                        placeholder="Search"
                                                        onChange={(e) => { handleSearch(e) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive" id="scrollableDivTable2" style={{ height: 500, overflowY: 'scroll' }}>
                                            <InfiniteScroll
                                                dataLength={data.length}
                                                next={() => searchData(searchPage.start)}
                                                hasMore={data.length < totalDataLength ? true : false}
                                                scrollableTarget="scrollableDivTable2"
                                            >
                                                <table className="table table-striped themeTable">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="cursor-pointer" >Name</th>
                                                            <th>Created</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ color: "#fff" }}  >
                                                        {
                                                            (data.length > 0) ? (
                                                                data.filter((project) => {
                                                                    return (project.campaignName.toLowerCase().includes(search.toLowerCase()))
                                                                }).map((project, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <ProjectRows
                                                                                data={project}
                                                                                searchData={searchData}
                                                                                setId={setId}
                                                                                setSearchPage={setSearchPage}
                                                                                searchPage={searchPage}
                                                                                setIsDelete={setIsDelete}
                                                                                isDelete={isDelete}
                                                                                setTotalDataLength={setTotalDataLength}
                                                                                isClicked={isClicked}
                                                                                setIsClicked={setIsClicked}
                                                                            />
                                                                        </React.Fragment>
                                                                    );
                                                                })
                                                            ) : ""
                                                        }

                                                        <tr className="loderTabel">
                                                            <td colSpan={4} style={{ border: "none", boxShadow: "none" }}>
                                                                {loader ?
                                                                    <i className="fa fa-spinner fa-spin mt-2 " style={{ color: "#f721a0", fontSize: "30px" }} />
                                                                    : data.length === 0 ? "You have not created any task yet!" : ""}
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </InfiniteScroll>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >


            <Footer />







        </>
    )
}

export default Projects
