import React, { useState } from 'react'
import modalCloseIcon from "../../images/modal-close.png";
import { Modal } from 'react-bootstrap';
import { sendData } from "../../Actions/TasksAction"
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
const ModalPopup = ({ show, hidePopup, dataValue, UserInputs, setUserInputs, id, promptsName, isPromptAvail, setIsPromptAvail, setTaskName, taskName, prompt }) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const submitData = (e) => {
        e.preventDefault();
        setLoader(true)
        let api_Data = {}
        if (isPromptAvail) {
            api_Data = {
                id: id,
                InputVar: UserInputs,
                campaignName: taskName

            }
        }
        else {
            api_Data = {
                id: id,
                InputVar: [],
                campaignName: taskName

            }
        }
        dispatch(sendData(api_Data, setLoader, navigate, setIsPromptAvail, hidePopup));

    }

    const onChangeHandel = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;

        let data = [...UserInputs]
        data[index] = {
            ...data[index],
            [name]: value
        }

        setUserInputs(data);

    }

    return (
        <>
            <Modal className="VideoModal dark" show={show} onHide={hidePopup} centered>
                <Modal.Body style={{ padding: "20px" }}>
                    <div onClick={hidePopup} className="vidClose">
                        <img alt="" src={modalCloseIcon} />
                    </div>
                    <div className="modal-txt">
                        <div className="modal-txt-top" style={{ paddingBottom: "20px" }}>
                            <h5>{promptsName}</h5>
                        </div>

                    </div>
                    <div style={{ padding: "30px 0" }}>
                        <form onSubmit={submitData}>
                            <p className='mb-2 text-center'>{prompt}</p>
                            <div className="row align-items-center">
                                <div className="col-md-12 mb-3">
                                    <label for="exampleFormControlInput1" style={{ textTransform: 'capitalize' }} >Task Name :</label>
                                    <input type="text"
                                        className="form-control add_form_control"
                                        id="exampleFormControlInput1"
                                        name=""
                                        onChange={(e) => { setTaskName(e.target.value) }}
                                        placeholder={`Enter your task name`}
                                        required />
                                </div>
                                {
                                    (UserInputs.length > 0) ?
                                        UserInputs.map((data, index) => {
                                            console.log(data);
                                            return (
                                                <React.Fragment>
                                                    <div className="col-md-12">
                                                        <div class="form-group">
                                                            {
                                                                isPromptAvail ? (
                                                                    <>
                                                                        <label for="exampleFormControlInput1" style={{ textTransform: 'capitalize' }} >{(dataValue[index].replace(/x/g, "Number "))} :</label>
                                                                        <input type="text"
                                                                            className="form-control add_form_control"
                                                                            id="exampleFormControlInput1"
                                                                            name={dataValue[index]}
                                                                            onChange={(e) => { onChangeHandel(e, index) }}
                                                                            placeholder={`Enter Your ${dataValue[index].replace(/x/g, "Number ")}`}
                                                                            required />
                                                                    </>
                                                                ) : ("")
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        }
                                        ) : ''
                                }
                            </div>
                            <div className="text-end">
                                <button type="submit" className="btn btn-primary">{loader ? "Submitting " : "Submit "}
                                    {loader ?
                                        <i className="fa fa-spinner fa-spin mx-1" />
                                        : ""}
                                </button>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalPopup
