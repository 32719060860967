import { commonAxios } from "../Global/CommonAxios"
import { setAlert } from "./AlertAction"

export const fetchPopularTask = (setPopular) => (dispatch, getState) => {
    commonAxios("fetch-popular-task", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setPopular(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err);
        })
}

export const fetchTasks = (data, tasks, setTasks, setPopular, setTaskLength, setLoader, isClicked) => (dispatch, getState) => {//here we are fetching task 
    commonAxios("fetch-task", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (isClicked === 1) {
                    if (res.data.totalProject < 15) {
                        setTasks(res.data.task)
                        setPopular(res.data.popularTask);

                    }
                    else {
                        let arr = [...tasks]
                        setTasks(arr.concat(res.data.task))
                        setPopular(res.data.popularTask)
                    }

                }
                else {
                    let arr = [...tasks]
                    setTasks(arr.concat(res.data.task))
                    setPopular(res.data.popularTask)
                }
                //console.log(res.data.popularTask);

                setTaskLength(res.data.totalProject);
            } else {
                setTasks([])
                setTaskLength(0);
            }
            setLoader(false)

        }).catch((err) => {
            setLoader(false)
            console.log(err)
        })
}
export const searchTask = (data, setTasks, setPopular, setLoader2, setLoader, search, setSearch, setTaskLength) => (dispatch, getState) => {
    commonAxios("search-task", data, dispatch, getState().auth.token)

        .then((res) => {
            if (res.status) {
                let data = []
                data = res.data.task
                setTasks(data);
                //setPopular(res.data.popularTask)
                setTaskLength(res.data.totalProject)
            } else {
                setTasks([]);
                //setPopular([])
                setTaskLength(0)

            }
            setLoader2(false)
            setLoader(false)
            // setSearch({
            //     ...search,
            //     keyword: ""
            // })  
        }).catch((err) => {
            setLoader2(false)
            setLoader(false);
            console.log(err);
        })
}
export const sendData = (api_Data, setLoader, navigate, setIsPromptAvail, hidePopup) => (dispatch, getState) => {
    commonAxios("generate-project", api_Data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/response-page?tId=${api_Data.id}&id=${res.data}`);
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
            setIsPromptAvail(false);
            hidePopup()
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            hidePopup()
        })

}
export const sendId = (id, setMainLoader, setData, setInputData, setInputStatus, setCategoryId) => (dispatch, getState) => {
    commonAxios("fetch-project", id, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data[0]);
                setInputData(res.data[0].data);
                setInputStatus(true)
                setCategoryId(res.data[0].categoryId);
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setMainLoader(false)
        }).catch((err) => {
            console.log(err);
        })
}

export const generateImageId = (id, content, setImageURL, setLoader, loader) => (dispatch, getState) => {
    const data = {
        prompts: content,
        id: id
    }
    commonAxios("generate-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImageURL(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setImageURL("")
            }
            setLoader({
                ...loader,
                three: false
            })
        }).catch((err) => {
            console.log(err);
        })
}

export const regenerateAIData = (data, tId, setData, setLoader) => (dispatch, getState) => {
    const allData = {
        cId: data.id,
        InputVar: data.data,
        id: tId
    }

    commonAxios("generate-script", allData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData({ ...data, orginalContent: res.data[0] })
                setLoader({
                    ...data,
                    two: false
                })

            } else {
                setLoader({
                    ...data,
                    two: false
                })
                dispatch((setAlert(res.msg, "danger")));
            }
        }).catch((err) => {
            setLoader({
                ...data,
                two: false
            })
            console.log(err);
        })
}

export const sendAIData = (data, setLoader, navigate) => (dispatch, getState) => {
    commonAxios("save-project", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate("/tasks");
                setLoader({
                    ...data,
                    one: false
                })
            }
            else {
                dispatch((setAlert(res.msg, "danger")))
            }
            setLoader({
                ...data,
                one: false
            })
        }).catch((err) => {
            setLoader({
                ...data,
                one: false
            })
            console.log(err);
        })
}

export const allProjects = (searchPage, setSearchPage, page, data, setData, setTotalDataLength, type, setLoader) => (dispatch, getState) => {

    commonAxios("fetch-all-project", searchPage, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (type === "delete") {
                    setData(res.data.projects)
                    setTotalDataLength(res.data.totalProjects)
                } else {

                    let arr = [...data]
                    setData(arr.concat(res.data.projects))
                    setTotalDataLength(res.data.totalProjects)
                }
                setSearchPage({
                    ...searchPage,
                    start: page + 1
                })
            } else {
                setTotalDataLength(0)
                setData([])
            }
            setLoader(false);
        }).catch((err) => {
            console.log(err);
        })

}

export const deleteProjectWithId = (id, searchPage, setSearchPage, searchData, Swal) => (dispatch, getState) => {
    const projectId = {
        id: id
    }
    commonAxios("delete-project", projectId, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"));

                setSearchPage({
                    ...searchPage,
                    start: 1
                })
                searchData(1, "delete")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close();
        }).catch((err) => {
            Swal.close();
            dispatch(setAlert(err.msg, "danger"))
            console.log(err);
        })
}